
import React, { useEffect, useRef, useState } from 'react'
import './ChatSection.css'
import ChatBubble from './ChatBubble/ChatBubble'
import { actionChangeUserLabel, actionReadUserMessages } from '../../../../actions'
import MainButton from '../../../../components/MainButton/MainButton'

const ChatSection = (props: any) => {

    const [searching, setSearching] = useState<boolean>(false)
    const [searchText, setSearchText] = useState<string>('')
    const [message, setMessage] = useState<string>('')
    const [highlightedIndex, setHighlightedIndex] = useState<number>(-1);
    const [moreOptionsOpen, setMoreOptionsOpen] = useState<boolean>(false);
    const [changeLabelMenu, setChangeLabelMenu] = useState<boolean>(false);
    const [labelSelected, setLabelSelected] = useState<any>(null);
    const [labels, setLabels] = useState<any>(null);

    const inputRef: any = useRef(null);

    useEffect(() => {
        actionReadUserMessages(props.currentChat[0]?.userNumber)
    }, [])

    useEffect(() => {
        if(props.labels) {
            const updatedItems = props.labels?.filter((item: any) => item?.label !== "CHATS");
            setLabels(updatedItems)
        }
    }, [props.labels])

    useEffect(() => {
        if(searching) handleFocus()
        else handleBlur()
    }, [searching])

    const handleFocus = () => {
        inputRef?.current?.focus();
    };

    const handleBlur = () => {
        inputRef?.current?.blur();
    };

    const getHighlightedMessages = () => {
        if (!props.currentChat || searchText?.length <= 1) return [];
    
        return props.currentChat
            .map((conversacion: any, index: number) => {
                const matches = conversacion?.mensaje
                    ?.toLowerCase()
                    ?.match(new RegExp(searchText.toLowerCase(), 'gi')) || []; // Find all occurrences
    
                return {
                    ...conversacion,
                    index,
                    matchCount: matches.length, // Count matches
                };
            })
            .filter((conversacion: any) => conversacion.matchCount > 0); // Keep only messages with matches
    };
    
    const highlightedMessages = getHighlightedMessages();
    const highlightedCount = highlightedMessages.reduce(
        (total: any, message: any) => total + message.matchCount,
        0
    );

    const handleNextHighlight = () => {
        if (highlightedCount === 0) return;
        setHighlightedIndex((prevIndex) => (prevIndex + 1) % highlightedCount);
    };

    const handlePreviousHighlight = () => {
        if (highlightedCount === 0) return;
        setHighlightedIndex((prevIndex) =>
            prevIndex === 0 ? highlightedCount - 1 : prevIndex - 1
        );
    };

    const handleSearchText = (text: string) => {
        setHighlightedIndex(-1)
        setSearchText(text)
    }
    
    const handleChangeLabelMenu = () => {
        setChangeLabelMenu(!changeLabelMenu)
        setMoreOptionsOpen(false)
    }

    return (
        <div className='chatSection-container'>
            <div className='chatSection-container-top'>
                <div className='chatSection-container-top-wrap'>
                    {!searching && <b className='chatSection-container-top-wrap-numero bold large'>{props.currentChat[0]?.userNumber}</b>}
                    {searching && 
                        <input
                            ref={inputRef}
                            className='chatSection-container-top-wrap-input'
                            type='text'
                            placeholder='Buscar...'
                            value={searchText}
                            onChange={(e: any) => handleSearchText(e?.target?.value)}
                        />
                    }
                    {searching && 
                        <div className='chatSection-container-top-wrap-highlight'>
                            {highlightedCount > 0 && <b className='chatSection-container-top-wrap-highlight-count regular medium'>{highlightedIndex + 1} de {highlightedCount}</b>}
                            <div className='chatSection-container-top-wrap-highlight-arrows'>
                                <span 
                                    className="material-symbols-rounded"
                                    onClick={handlePreviousHighlight}
                                    style={{ 
                                        cursor: 'pointer', 
                                        backgroundColor: 'var(--gray100)',
                                        border: '1px solid var(--gray200)',
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5
                                    }}
                                >chevron_left</span>
                                <span 
                                    className="material-symbols-rounded"
                                    onClick={handleNextHighlight}
                                    style={{ 
                                        cursor: 'pointer', 
                                        backgroundColor: 'var(--gray100)',
                                        border: '1px solid var(--gray200)',
                                        borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5
                                    }}
                                >chevron_right</span>
                            </div>
                        </div>
                    }
                    <div className='chatSection-container-top-wrap-icons'>
                        <span 
                            className="material-symbols-rounded" 
                            onClick={() => setSearching(!searching)} 
                            style={{ color: 'var(--primary500)', cursor: 'pointer' }}
                        >{searching ? 'close' : 'search'}</span>
                        <div className='chatSection-container-top-wrap-icons-more'>
                            <span 
                                className="material-symbols-rounded" 
                                onClick={() => setMoreOptionsOpen(!moreOptionsOpen)} 
                                style={{ color: 'var(--primary500)', cursor: 'pointer' }}
                            >more_vert</span>
                            {moreOptionsOpen &&
                                <div className='chatSection-container-top-wrap-icons-more-list'>
                                    <b className='chatSection-container-top-wrap-icons-more-list-text regular medium' onClick={() => handleChangeLabelMenu()}>Cambiar Etiqueta</b>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='chatSection-container-chat'>
                {props.currentChat?.map((conversacion: any, index: any) => {
                    const messageHighlightIndex = highlightedMessages.findIndex(
                        (msg: any) => msg.index === index
                    );
                    
                    const isSelected = 
                        messageHighlightIndex !== -1 && 
                        highlightedMessages.slice(0, messageHighlightIndex)
                            .reduce((sum: any, msg: any) => sum + msg.matchCount, 0) <= highlightedIndex &&
                        highlightedIndex < 
                        highlightedMessages.slice(0, messageHighlightIndex + 1)
                            .reduce((sum: any, msg: any) => sum + msg.matchCount, 0);

                    return (
                        <ChatBubble
                            key={index}
                            mensaje={conversacion?.message}
                            messageBody={conversacion?.messageBody}
                            fecha={conversacion?.timestamp}
                            reciever={conversacion?.senderType === "USER"}
                            status={conversacion?.messageStatus}
                            errorMessage={conversacion?.errorMessage}
                            senderType={conversacion?.senderType}
                            searchText={searchText}
                            occurrenceSelected={isSelected}
                            messageType={conversacion?.messageType}
                        />
                    );
                })}
            </div>
            <div className='chatSection-container-sender'>
                <div className='chatSection-container-sender-wrap'>
                    <input
                        className='chatSection-container-sender-wrap-input'
                        placeholder='Escribe un mensaje'
                        value={message}
                        onChange={(e: any) => setMessage(e.target.value)}
                    />
                    <span 
                        className="material-symbols-rounded" 
                        onClick={() => {}} 
                        style={{ color: 'var(--primary500)', cursor: 'pointer' }}
                    >send</span>
                </div>
            </div>
            {changeLabelMenu &&
                <div className='chatSection-container-sender-modal'>
                    <div className='chatSection-container-sender-modal-wrap'>
                        <b className='chatSection-container-sender-modal-title large bold'>Selecciona una Etiqueta</b>
                        <span 
                            className="material-symbols-rounded" 
                            onClick={() => setChangeLabelMenu(false)} 
                            style={{ color: 'var(--primary500)', cursor: 'pointer', position: 'absolute', right: 5, top: 5 }}
                        >close</span>
                        <div className='chatSection-container-sender-modal-list'>
                            {labels?.map((label: any, index: any) => (
                                <div key={index} className='chatSection-container-sender-modal-list-item' onClick={() => setLabelSelected(label)}>
                                    <div className='chatSection-container-sender-modal-list-item-wrapp'>
                                        <span className="material-symbols-rounded" style={{fontSize: 14}}>{label?.icon}</span>
                                        <b className='chatSection-container-sender-modal-list-item-wrapp-label medium regular'>{label?.label}</b>
                                        <div className='chatSection-container-sender-modal-list-item-wrapp-circle'>
                                            <div 
                                                className='chatSection-container-sender-modal-list-item-wrapp-circle-inner'
                                                style={{ transform: label?.label === labelSelected?.label ? `scale(${1})` : `scale(${0})` }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className='chatSection-container-sender-modal-button'>
                            <MainButton
                                text='OK'
                                onPress={() => {
                                    actionChangeUserLabel(props.currentChat[0]?.userNumber, labelSelected?.label)
                                    setChangeLabelMenu(false)
                                    setLabelSelected(null)
                                }}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default ChatSection;
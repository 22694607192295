import React, { useState, useRef, useEffect } from 'react'
import './TableContent.css'
import SimpleTextComponent from './SimpleTextComponent/SimpleTextComponent';
import TitleAndSubTitleComponent from './TitleAndSubTitleComponent/TitleAndSubTitleComponent';
import TextWithBackGround from './TextWithBackGround/TextWithBackGround';
import AlertComponent from './AlertComponent/AlertComponent';
import TextWithColor from './TextWithColor/TextWithColor';
import DateComponent from './DateComponent/DateComponent';
import Modal from 'react-modal';
import DeleteRowModal from '../Modals/DeleteRowModal/DeleteRowModal';
import DeleteComponent from './DeleteComponent/DeleteComponent';
import { actionsReadContact } from '../../actions';
import MailComponent from './MailComponent/MailComponent';
import EstadoCoberturaListModal from '../Modals/EstadoCoberturaListModal/EstadoCoberturaListModal';
import TableBox from './TableBox/TableBox';
import GlobalDelete from './GlobalActions/GlobalDelete/GlobalDelete';
import GlobalStatusChange from './GlobalActions/GlobalStatusChange/GlobalStatusChange';
import GlobalDownload from './GlobalActions/GlobalDownload/GlobalDownload';
import IndividualActions from './IndividualActions/IndividualActions';
import { useNavigate } from 'react-router-dom';
import CopyComponent from './CopyComponent/CopyComponent';
import CopyTextComponent from './CopyTextComponent/CopyTextComponent';
import GlobalWhatsapp from './GlobalActions/GlobalWhatsapp/GlobalWhatsapp';
import InvisibleComponent from './InvisibleComponent/InvisibleComponent';
import TextWithBorderColor from './TextWithBorderColor/TextWithBorderColor';
import EstadoPagoListModal from '../Modals/EstadoPagoListModal/EstadoPagoListModal';

const TableContent = (props) => {  
    
    const [hoveredR, setHoveredR] = useState(null);
    const [deleteRowAlert, setDeleteRowAlert] = useState(false);
    const [deleteRowData, setDeleteRowData] = useState(null);
    const [deleteId, setDeleteId] = useState(null);
    const [estadoCoberturaListAlert, setEstadoCoberturaListAlert] = useState(false);
    const [estadoPageListAlert, setEstadoPagoListAlert] = useState(false);
    const [selectedList, setSelectedList] = useState([]);
    const [openSelection, setOpenSelection] = useState(false);
    const navigate = useNavigate();
    const optionSelectionRef = useRef();

    const listIndividual = [
        {
            'label': 'Editar',
            'show': props.canEditCustom,
            'action': (id) => props.editTableItem(true)
        },
        {
            'label': 'Eliminar',
            'show': props.canDelete,
            'action': (id) => setDeleteRowAlert(true)
        },
        {
            'label': 'Ver cotización',
            'show': props.formData?.individualActions?.includes('quote'),
            'action': (id) => navigate('/cotizaciones/create', {state: {individualId: id}})
        }
    ]

    const getComponent = (indexB, b, isHovered) => {
        const dataStyle = {
            "AlertComponent": { component: AlertComponent },
            "TextWithBackGround": { component: TextWithBackGround },
            "TitleAndSubTitleComponent": { component: TitleAndSubTitleComponent },
            "TextWithColor": { component: TextWithColor },
            "TextWithBorderColor": { component: TextWithBorderColor },
            "DateComponent": { component: DateComponent },
            "DeleteComponent": { component: DeleteComponent },
            "MailComponent": { component: MailComponent },
            "CopyTextComponent": { component: CopyTextComponent },
            "InvisibleComponent": { component: InvisibleComponent },
            undefined: { component: SimpleTextComponent }
        }
        
        if(props.headers && indexB !== null) {
            const StepComponent = dataStyle[props.headers[indexB]?.component]?.component;
    
            return (
                <StepComponent
                    key={indexB}
                    data={b}
                    fill={props.headers[indexB]?.config?.fill}
                    style={props.headers[indexB]?.style}
                    hovered={isHovered}
                    userData={props.userData}
                    refreshData={props.refreshData}
                    sendNotification={props.sendNotification}
                />
            )
        } return null;

    }

    const getGlobalActionComponent = (data) => {
        const globalActionStyle = {
            "delete": { component: GlobalDelete },
            "compare_arrows": { component: GlobalStatusChange },
            "txt": { component: GlobalDownload },
            "whatsapp": { component: GlobalWhatsapp },
            undefined: { component: null }
        }
        
        if(data?.icon) {
            const GlobalActionComponent = globalActionStyle[data?.icon]?.component;
    
            return (
                <GlobalActionComponent
                    selectedList={selectedList}
                    tooltip={data?.tooltip}
                    list={data?.list}
                    refreshData={props.refreshData}
                    sendNotification={props.sendNotification}
                    userData={props.userData}
                    deleteData={props.deleteData}
                    deleteMultipleData={props.deleteMultipleData}
                    dataRows={props.dataRows}
                />
            )
        } return null;

    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (optionSelectionRef.current && !optionSelectionRef.current.contains(event.target)) {
                setOpenSelection(false) 
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [optionSelectionRef]);

    const transformStringToFunction = (str) => {
        return new Function('data', `return ${str}`);
    };

    const handleRowClick = (event, data) => {
        const row = event.currentTarget;
        try {
            // Parse the JSON string into a JavaScript object
            let jsonObject = JSON.parse(data[1]);

            // Extract the value of the "name" property
            let value = jsonObject.name;
            if(props.hasDetails) {
                if (value) props.goToDetails(value, event)
                else props.goToDetails(data[1], event)
            }
        } catch {
            if(props.hasDetails) props.goToDetails(data[1], event)
        }
        const inputString = props.deleteData?.data;
        const transformedFunction = transformStringToFunction(inputString);
        const result = transformedFunction(data);
        setDeleteRowData(result)
        if(props.setEditData)props.setEditData(data)
        setDeleteId(data[1])
    };

    const handleDataClick = (event, data) => {
        if(event?.currentTarget?.innerText?.includes("Descargar como .txt")) event.stopPropagation();
        if(event?.currentTarget?.innerText?.includes('arrow_drop_down')) event.stopPropagation();
        if(event?.currentTarget?.innerText === "delete" && props.deleteData) setDeleteRowAlert(true)
        if(event?.currentTarget?.innerText === "drafts") {
            try {
                const parsedObject = JSON.parse(data);
                actionsReadContact(parsedObject.id)
            } catch (error) {
            }
        }
    }

    const DeleteRowAlert = () => {
        return (
            <Modal
                isOpen={deleteRowAlert}
                onRequestClose={() => {
                    setDeleteRowAlert(false)
                    window.location.reload();
                }}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '25px',
                        width: '30%',
                        padding: '20px'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 10
                    }
                }}
            >
                <DeleteRowModal
                    title={props.deleteData?.title}
                    data={deleteRowData}
                    deleteId={deleteId}
                    subTitleSuccess={props.deleteData?.subTitleSuccess}
                    onDismiss={() => setDeleteRowAlert(false)}
                />   
            </Modal>
        )
    }

    const EstadoCoberturaListAlert = () => {
        return (
            <Modal
                isOpen={estadoCoberturaListAlert}
                onRequestClose={() => setEstadoCoberturaListAlert(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '25px',
                        width: '640px',
                        padding: '20px'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 10
                    }
                }}
            >
                <EstadoCoberturaListModal
                    onDismiss={() => setEstadoCoberturaListAlert(false)}
                />   
            </Modal>
        )
    }

    const EstadoPagoListAlert = () => {
        return (
            <Modal
                isOpen={estadoPageListAlert}
                onRequestClose={() => setEstadoPagoListAlert(false)}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        borderRadius: '25px',
                        width: '640px',
                        padding: '20px'
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        zIndex: 10
                    }
                }}
            >
                <EstadoPagoListModal
                    onDismiss={() => setEstadoPagoListAlert(false)}
                />   
            </Modal>
        )
    }

    const isDisabled = (row) => {
        try {
            const res = JSON.parse(row[6]);
            return res?.h1 === 'Emitida'
        } catch (error) {
            try {
                const res = JSON.parse(row[7]);
                return res?.h1 === 'Emitida'
            } catch {
                
            }
            return false
        }
    }

    const notViewedMessage = (row) => {
        try {
            const object = JSON.parse(row[5]);
            return (object?.leido === 'false')
        } catch (error) {
            return false
        }
    }

    const handleHeadInfoClick = (info) => {
        switch(info) {
            case 'EstadoCoberturaList':
                setEstadoCoberturaListAlert(true)
                break;
            case 'EstadoPagoList':
                setEstadoPagoListAlert(true)
                break;
            default:
                break;
        }
    }

    const handleSelectedRow = (row) => {
        const list = [...selectedList];
        try {
            const object = JSON.parse(row[1]);
            var name;

            if(object?.name) {
                name = object?.name
            }
            else {
                name = object
            }
            // Check if the name already exists in the list
            const index = list.indexOf(name);

            if (index !== -1) {
                // If the name exists, remove it from the list
                list.splice(index, 1);
            } else {
                // If the name doesn't exist, add it to the list
                list.push(name);
            }
            setSelectedList(list);
        } catch (error) {
        }
    }

    const isSelected = (row) => {
        try {
            const object = JSON.parse(row[1]);
            if(object?.name) {
                return selectedList?.includes(object?.name);
            }
            else {
                return selectedList?.includes(object);
            }
        } catch (error) {
            return false
        }
    }

    const handleMassiveSeleccion = (control) => {
        if(selectedList.length !== 0 && !control) {
            setSelectedList([])
        } else {
            const list = [];
            props.dataRows?.map((a) => {
                try {
                    const object = JSON.parse(a[1]);
                    if(object?.name) {
                        list?.push(object?.name);
                    }
                    else {
                        list?.push(object);
                    }
                } catch (error) {
                }
            })
            setSelectedList(list)
        }
    }

    const emptyTable = () => {
        if(props.dataConfig?.filter?.length !== 0) { //En base a un filtro
            return (
                <div className='tableContent-container-empty'>
                    <span className="material-symbols-rounded" style={{ color: 'var(--gray200)', fontSize: 60}}>sentiment_dissatisfied</span>
                    <h5 className='tableContent-container-empty-title light'>¡Lo sentimos mucho!</h5>
                    <p className='tableContent-container-empty-text regular'>No encontramos resultados que coincidan con tu búsqueda.<br/>Por favor, intentá nuevamente.</p>
                </div>
            )
        } else {
            return (
                <div className='tableContent-container-empty'>
                    <span className="material-symbols-rounded" style={{ color: 'var(--gray200)', fontSize: 60}}>schedule</span>
                    <h5 className='tableContent-container-empty-title light'>Lo bueno se hace esperar</h5>
                    <p className='tableContent-container-empty-text regular'>Actualmente no tenés {props.title?.toLowerCase()}.</p>
                </div>
            )
        }
    }

    const enableGlobalAction = (row) => {
        try {
            const res = JSON.parse(row[2]);
            return res?.h1 !== "Solicitud pendiente incompleta"
        } catch (error) {
            return true
        }
    }

    return (
        <div className='tableContent'>
            <div className='tableContent-wrapper'>
                <div className='tableContent-container'>
                    <table id='main-table'>
                        <tbody>
                            <tr>
                                {props.formData?.globalActions && <th style={{ width: '0%' }} className='tableContent-container-table-empty'></th>}
                                {props.headers?.map((a, index) => (
                                    a?.component !== "InvisibleComponent" &&
                                    <th key={index}>
                                        <div className='tableContent-container-tableHeaderContainer'>
                                            <b className='tableContent-container-tableHeaderContainer-text bold small'>{a.title?.toUpperCase()}</b>
                                            {a.info && <span onClick={() => handleHeadInfoClick(a.info)} className="material-symbols-rounded" style={{fontSize: 20, marginLeft: 5, cursor: 'pointer', color: 'var(--gray400)'}}>info</span>}
                                        </div>
                                    </th>
                                ))}
                                {props.formData?.individualActions && <th style={{ width: '0%' }} className='tableContent-container-table-empty'></th>}
                            </tr>
                            {props.formData?.globalActions && <tr>
                                <th id="asd" className='tableContent-container-table-global' colSpan={props.headers?.length + 1}>
                                    <div className='tableContent-container-table-global-content'>
                                        <div className='tableContent-container-table-global-content-box'>
                                            <div 
                                                className='tableContent-container-table-global-box'
                                                style={{
                                                    backgroundColor: selectedList?.length !== 0 ? 'var(--primary500)' : 'transparent'
                                                }}
                                                onClick={() => handleMassiveSeleccion()}
                                            >
                                                {selectedList?.length !== 0 ?
                                                    <span className="material-symbols-rounded" style={{ color: 'var(--white)', fontSize: 15 }}>{selectedList?.length === props.dataRows?.length ? 'done' : 'horizontal_rule'}</span>
                                                    :
                                                    null
                                                }
                                            </div>
                                            <span className="tooltip-box">Seleccionar</span>
                                        </div>
                                        <div 
                                            className='tableContent-container-table-global-content-selection'
                                            style={{
                                                transform: openSelection ? 'rotate(180deg)' : 'rotate(0deg)',
                                                transition: 'all 0.5ms ease'
                                            }}
                                        >
                                            <span 
                                                className="material-symbols-rounded" 
                                                style={{ 
                                                    color: 'var(--primary500)', 
                                                    fontSize: 15,
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => setOpenSelection(!openSelection)}
                                            >arrow_drop_down</span>
                                        </div>
                                        {selectedList?.length !== 0 && <div className='tableContent-container-table-global-content-actions'>
                                            {props.formData?.globalActions?.map((a) => (
                                                <div className='tableContent-container-table-global-content-actions-item'>
                                                    {getGlobalActionComponent(a)}
                                                </div>
                                            ))}
                                        </div>}
                                        {openSelection && 
                                            <div ref={optionSelectionRef} className='tableContent-container-table-global-content-selection-list'>
                                                <b 
                                                    className='tableContent-container-table-global-content-selection-list-text regular medium'
                                                    style={{ 
                                                        borderTopLeftRadius: 15, 
                                                        borderTopRightRadius: 15
                                                    }}
                                                    onClick={() => {
                                                        handleMassiveSeleccion(true)
                                                        setOpenSelection(false)
                                                    }}
                                                >Seleccionar todo</b>
                                                <b 
                                                    className='tableContent-container-table-global-content-selection-list-text regular medium'
                                                    style={{ 
                                                        borderBottomLeftRadius: 15, 
                                                        borderBottomRightRadius: 15
                                                    }}
                                                    onClick={() => {
                                                        handleMassiveSeleccion(false)
                                                        setOpenSelection(false)
                                                    }}
                                                >Borrar selección</b>
                                            </div>
                                        }
                                    </div>
                                </th>
                            </tr>}
                            {props.dataRows?.map((a, indexA) => (
                                <tr 
                                    key={indexA}
                                    onMouseOver={() => props.hasDetails ? setHoveredR(indexA) : {}}
                                    onMouseLeave={() => props.hasDetails ? setHoveredR(null) : {}}
                                    onClick={(event) => isDisabled(a) ? {} : handleRowClick(event, a)}
                                    style={{ 
                                        opacity: isDisabled(a) ? 0.4 : 1,
                                        backgroundColor: notViewedMessage(a) ? 'var(--accent500)' : 'auto'
                                    }}
                                    className={props.hasDetails ? "table-rowHover" : "table-rowNoHover"}
                                >
                                    {props.formData?.globalActions && <td 
                                        onClick={(event) => {
                                            if(enableGlobalAction(a)) {
                                                event.stopPropagation();
                                                handleSelectedRow(a);
                                            }
                                        }}
                                    >
                                        {enableGlobalAction(a) ?
                                            <TableBox
                                                isSelected={isSelected(a)}
                                            />
                                            :
                                            null
                                        }
                                    </td>}
                                    {a.map((b, indexB) => (props.headers[indexB]?.component !== "InvisibleComponent" &&
                                        <td onClick={(event) => isDisabled(a) ? {} : handleDataClick(event, b)} key={indexB}>{getComponent(indexB, b, indexA === hoveredR)}</td>
                                    ))}
                                    {props.formData?.individualActions && <td><IndividualActions actionClicked={(item) => item.action(a[2])} list={listIndividual} disabled={isDisabled(a)}/></td>}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {(!props.dataRows || props.dataRows?.length === 0) && emptyTable()}
                    </div>
                </div>
                {(props.dataRows && props.dataRows?.length !== 0) && 
                    <div className='tableContent-container-pageContainer'>
                        <div className='tableContent-container-pageContainer-main'>
                            <b className='tableContent-container-pageContainer-main-totalText regular small'>{props.page?.totalItems === 1 ? props.page?.totalItems + ' Resultado encontrado' : props.page?.totalItems + ' Resultados encontrados'}</b>
                            <div className='tableContent-container-pageContainer-main-pages'>
                                {props.page?.currentPage !== 1 &&
                                    <div className='tableContent-container-pageContainer-main-pages-wrap' onClick={() => props.prevPage()}>
                                        <span className="material-symbols-rounded" style={{ color: 'var(--primary500)', fontSize: 24}}>chevron_left</span>
                                        <b className='tableContent-container-pageContainer-main-pages-wrap-text regular small'>Anterior</b>
                                    </div>
                                }
                                <b className='tableContent-container-pageContainer-main-pages-number regular small'>{props.page?.currentPage} de {props.page?.pages}</b>
                                {props.page?.currentPage !== props.page?.pages &&
                                    <div className='tableContent-container-pageContainer-main-pages-wrap' onClick={() => props.nextPage()}>
                                        <b className='tableContent-container-pageContainer-main-pages-wrap-text regular small'>Siguiente</b>
                                        <span className="material-symbols-rounded" style={{ color: 'var(--primary500)', fontSize: 24}}>chevron_right</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
                {DeleteRowAlert()}
                {EstadoCoberturaListAlert()}
                {EstadoPagoListAlert()}
        </div>
    );
}

export default TableContent;
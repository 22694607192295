
import React, { useEffect, useState } from 'react'
import './Chats.css'
import ChatBox from './ChatBox/ChatBox'
import ChatSection from './ChatSection/ChatSection'
import { actionGetChatsFromUser } from '../../../actions'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

const Chats = (props: any) => {

    const [search, setSearch] = useState<string>('')
    const [selectedChat, setSelectedChat] = useState<any>(null)
    const [selectedChatMessages, setSelectedChatMessages] = useState<any>(null)
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [messages, setMessages] = useState<any>(null);

    useEffect(() => {
        handleUrlParams()
    }, [searchParams])

    useEffect(() => {
        if(props.messages) setMessages(props.messages)
    }, [props.messages])

    const handleUrlParams = () => {
        const userNumber: any = searchParams.get("user_number")
        setSelectedChatMessages(null)
        setSelectedChat(null)
        if(userNumber) {
            setSelectedChat(userNumber)
            actionGetChatsFromUser(userNumber, setSelectedChat, setSelectedChatMessages)
        }
    }

    const userNumberSelected = (userNumber: any) => {
        const searchParams = new URLSearchParams(location?.search);
        searchParams?.set('user_number', userNumber);
        navigate(`${location?.pathname}?${searchParams?.toString()}`);
    };

    const handleNumberSearch = (text: string) => {
        setSearch(text);
        if (text === '') {
            setMessages(props.messages);
            return;
        }
        const filteredMessages = props.messages?.filter((message: any) => message.userNumber.includes(text));
        setMessages(filteredMessages);
    };

    return (
        <div className='chats-container'>
            <div className='chats-container-hub'>
                <div className='chats-container-hub-title'>
                    <h5 className='chats-container-hub-title-text'>{props.label}</h5>
                    <span className="material-symbols-rounded" style={{ color: 'var(--primary500)', marginRight: 10 }}>edit_square</span>
                    <span className="material-symbols-rounded" style={{ color: 'var(--primary500)' }}>tune</span>
                </div>
                <div className='chats-container-hub-searcher'>
                    <input
                        className='chats-container-hub-searcher-input'
                        placeholder='Buscar...'
                        value={search}
                        onChange={(e: any) => handleNumberSearch(e.target.value)}
                    />
                    <span className="material-symbols-rounded" style={{ color: 'var(--gray300)' }}>search</span>
                </div>
                <div className='chats-container-hub-chats'>
                    {messages?.map((message: any) => (
                        <ChatBox
                            chat={message}
                            selectChat={() => userNumberSelected(message?.userNumber)}
                            chatSelected={message?.userNumber === selectedChat}
                        />
                    ))}
                </div>
            </div>
            {selectedChatMessages && 
                <ChatSection currentChat={selectedChatMessages} labels={props.labels}/>
            }
        </div>
    );
}

export default Chats;

import React from 'react'
import './Label.css'

const Label = (props: any) => {

    return (
        <div 
            className='label-container'
            onClick={props.selectLabel}
        >
            <span className="material-symbols-rounded" style={{ color: props.labelSelected ? 'var(--primary200)' : 'var(--white)' }}>{props.icon}</span>
            <span className='label-container-toolTip'>{props.label}</span>
        </div>
    );
}

export default Label;

import React from 'react'
import './ChatBox.css'
import Moment from 'moment/moment'

const ChatBox = (props: any) => {

    return (
        <div 
            className='chatBox-container'
            style={{ backgroundColor: props.chatSelected ? 'var(--gray100)' : 'transparent'}}
            onClick={props.selectChat}
        >
            <div className='chatBox-container-main'>
                <div className='chatBox-container-main-top'>
                    <b className='chatBox-container-main-top-numero bold medium'>{props.chat?.userNumber}</b>
                    <b className='chatBox-container-main-top-fecha regular small'>{Moment(props.chat?.timestamp).format('DD/MM/YYYY')}</b>
                </div>
                <div className='chatBox-container-main-bot'>
                    <span 
                        className="material-symbols-rounded" 
                        style={{ 
                            fontSize: 16, 
                            color: props.chat?.messageStatus === 'READ' ? 'var(--primary500)' : props.chat?.messageStatus === 'FAILED' ? 'var(--errorPrimary)' : 'var(--gray300)' 
                        }}
                    >
                        {props.chat?.messageStatus === 'SENT' ? 'check' : props.chat?.messageStatus === 'FAILED' ? 'error' : 'done_all'}
                    </span>
                    <b className='chatBox-container-main-bot-ultimoMensaje regular medium'>{props.chat?.lastMessage}</b>
                    {props.chat?.unReadMessages > 0 && <div className='chatBox-container-main-bot-mensajesNoLeidos'>
                        <b className='chatBox-container-main-bot-mensajesNoLeidos-number small'>{props.chat?.unReadMessages}</b>
                    </div>}
                </div>
            </div>
        </div>
    );
}

export default ChatBox;
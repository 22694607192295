import axios from './instanceActions'
import { 
    netGetLabels,
    netGetChatsByLabel,
    netGetChats,
    netGetChatsFromUser,
    netChangeUserCategory,
    netReadUserMessages
} from "../net/Connector";

export const actionGetLabels = (setLabels: any) => {
    axios.get(netGetLabels())
        .then((response: any) => {
            const res = [...response.data]
            const chats = {
                id: 0,
                label: "CHATS",
                icon: "chat"
            }
            res.unshift(chats)
            setLabels(res)
        })
        .catch((error) => {
        })
}

export const actionGetChatsByLabel = (setMessages: any, labelSelected: any) => {
    axios.get(labelSelected ? netGetChatsByLabel(labelSelected) : netGetChats())
        .then((response: any) => {
            setMessages(response.data)
        })
        .catch((error) => {
        })
}

export const actionGetChatsFromUser = (userNumber: any, setSelectedChat: any, setSelectedChatMessages: any) => {
    axios.get(netGetChatsFromUser(userNumber))
        .then((response: any) => {
            setSelectedChat(userNumber)
            setSelectedChatMessages(response.data)
        })
        .catch((error) => {
        })
}

export const actionReadUserMessages = (userNumber: any) => {
    axios({
        method: 'post',
        url: netReadUserMessages(userNumber),
    })
        .then((response: any) => {
        })
        .catch((error) => {
        })
}

export const actionChangeUserLabel = (userNumber: any, label: any) => {
    axios({
        method: 'post',
        url: netChangeUserCategory(userNumber, label),
    })
        .then((response: any) => {
        })
        .catch((error) => {
        })
}
import axios from './instanceActions'
import {
    netPolizaData, 
    netPolizaDataFromClaim,
    netCheckoutData,
    netChangeStatus,
    netCheckOutRequerements,
    netUserDataFromPoliza,
    netDownloadPolicyMA,
    netDownloadPolicySC,
    netGetQuoteQuestions,
    netGetCobranzaData,
    cuponPagoNet,
    tarjetaCirculacionNet
} from "../net/Connector";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import logoMA from '../assets/images/Logo-mercantil.png';
import logoSC from '../assets/images/Logo-san-cristobal.png';
import logoLC from '../assets/images/Logo-la-caja.png';
import Moment from 'moment/moment'
import { saveAs } from 'file-saver';
import { createPdf } from '../util/CreateCarfacilCertificadoPdf';

export const getPolizaData = (userId: any, setPolizaData: any, from: any, setLoading: any) => {
    setLoading(true)
    let url = netPolizaData(userId)
    if(from === 'claim') {
        url = netPolizaDataFromClaim(userId)
    }
    axios.get(url)
        .then((response) => {
            setPolizaData(response.data)
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
        })
};

export const getCheckoutData = (id: any, setCheckoutData: any, setLoading: any) => {
    setLoading(true)
    axios.get(netCheckoutData(id))
        .then((response) => {
            setCheckoutData(response.data)
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
        })
}

export const getCheckOutRequerements = (id: any, setCheckOutRequerements: any, setCheckOutRequerementsTOS: any, setLoading: any) => {
    setLoading(true)
    axios.get(netCheckOutRequerements(id))
        .then((response) => {
            setCheckOutRequerementsTOS(response?.data?.filter((a: any) => a?.input?.type === "ok"))
            setCheckOutRequerements(response?.data?.filter((a: any) => a?.input?.type !== "ok"))
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
        })
}

export const putChangeStatus = (data: any, refreshData: any, sendNotification: any, setLoading: any) => {
    setLoading(true);
    axios({
        method: 'put',
        url: netChangeStatus(),
        data: data,
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response: any) => {
            refreshData();
            sendNotification({success: true, text: 'El estado de la cobertura se cambió con éxito.'})
            setTimeout(() => {
                sendNotification({success: false, text: ''})
            }, 5000)
            setLoading(false);
        })
        .catch((error) => {
            sendNotification({success: false, text: 'No pudimos cambiar el estado de la cobertura. Por favor, intentá nuevamente.'})
            setTimeout(() => {
                sendNotification({success: false, text: ''})
            }, 5000)
            setLoading(false);
        })
}

export const actionDownloadPolicyMA = (id: any, setLoadingDownload: any) => {
    setLoadingDownload(true)
    axios.get(netDownloadPolicyMA(id))
        .then((responsePoliza) => {
            window.open(responsePoliza.data?.url, '_blank')
            setLoadingDownload(false)
        })
        .catch((error) => {
            setLoadingDownload(false)
        })
}

export const actionDownloadPolicySC = (id: any, setLoadingDownload: any) => {
    setLoadingDownload(true);
    axios.get(netDownloadPolicySC(id), { responseType: 'blob' }) // Set responseType to 'blob'
        .then((responsePoliza) => {
            const blob: Blob = responsePoliza.data; // Access the blob data
            const blobUrl = URL.createObjectURL(blob);

            saveAs(blob, 'file.pdf'); // Use saveAs from file-saver to save the blob as a file
            setLoadingDownload(false);
        })
        .catch((error) => {
            setLoadingDownload(false);
        });
};


export const libreDeudaAction = (policyId: any, setLoading: any) => {
    setLoading(true)
    axios.get(netPolizaData(policyId))
    .then((responsePoliza) => {
        axios.get(netUserDataFromPoliza(policyId))
        .then((responseUser) => {
            axios.get(netCheckoutData(responsePoliza?.data?.checkoutId))
            .then((responseCheckout) => {
                const userData = responseUser?.data
                const policyData = responsePoliza?.data
                const checkoutData = responseCheckout?.data
                const insurerId = checkoutData?.offer?.packages?.filter((a: any) => a.id === checkoutData?.offer?.currentPackage)[0]?.insurer?.id

                const doc: any = new jsPDF({
                    orientation: 'portrait', // 'portrait' or 'landscape'
                    unit: 'cm',
                    format: 'a4', // 'a3', 'a4', 'a5', etc.
                });
            
                const imgWidth = insurerId === 40 ? 1.5 : 3; // Adjust the image width as needed
                const imgHeight = insurerId === 40 ? 2 : 0.6; // Adjust the image height as needed
                const imgX = doc.internal.pageSize.getWidth() - imgWidth - 2; // X position
                const imgY = 2; // Y position
            
                // Add the converted SVG image to the PDF
                let logo = logoMA;

                if (insurerId === 18) {
                    logo = logoSC;
                } else if (insurerId === 40) {
                    logo = logoLC;
                }

                doc.addImage(logo, 'PNG', imgX, imgY, imgWidth, imgHeight);
            
                doc.text('Sr./Sra.: ' + userData?.name + ' ' + userData?.lastName, 2, 4); // Add text to the document

                // Obtenemos la fecha actual
                const fecha = new Date();
                // Array con los nombres de los meses
                const nombresMeses = [
                    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
                    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
                ];
                // Obtenemos el nombre del mes actual
                const nombreMesActual = nombresMeses[fecha.getMonth()];
    
                const mainText = 'Por la presente certificamos que la póliza ' + policyData?.number + ' (seguro automotor), emitida a nombre de ' + 
                userData?.name + ' ' + userData?.lastName + ', con vigencia desde el ' + Moment(policyData?.fechaDesde).format('DD/MM/YYYY') + 
                ' hasta ' + Moment(policyData?.fechaHasta).format('DD/MM/YYYY') + '.\n\nLa misma cuenta con sus respectivos pagos al día,' + 
                ' no arrojando deuda alguna vencida a la fecha.\n\nSe extiende el presente para ser presentado ante quien corresponda,' +
                ' a los ' + Moment(new Date()).format('DD') + ' días del mes de ' + nombreMesActual + ' del año ' + 
                Moment(new Date()).format('YYYY') + '.'
    
                const lines = doc.splitTextToSize(mainText, 17); // Adjust the width as needed
    
                doc.text(lines, 2, 7); // Add text to the document
    
                //Bottom Info
                const fontSize = 14; // Set the font size
                
                function addCenteredText(doc: any, text: any, fontSize: any, yPosition: any) {
                    const textWidth = doc.getStringUnitWidth(text) * fontSize / doc.internal.scaleFactor;
                    const textX = (doc.internal.pageSize.getWidth() - textWidth) / 2;
                    doc.text(text, textX, yPosition);
                }
                
                const texts17 = [
                    "Mercantil Andina Seguros",
                    "(54 11) 4339-5600 Int. 5609",
                    "Av. Belgrano 672",
                    "C.A.B.A, Buenos Aires, Argentina",
                    "www.mercantilandina.com.ar"
                ];

                const texts18 = [
                    "San Cristóbal Seguros",
                    "(54 11) 2628-6423",
                    "Av. Córdoba 950",
                    "C.A.B.A, Buenos Aires, Argentina",
                    "www.sancristobal.com.ar"
                ];

                const texts40 = [
                    "Caja de Seguros S.A.",
                    "Fitz Roy 957 C.A.B.A.",
                    "www.seguros.lacaja.com.ar"
                ];
                
                const positions = [15, 15.7, 16.4, 17.1, 17.8];
                
                if (insurerId === 18) {
                    texts18.forEach((text, index) => {
                        addCenteredText(doc, text, fontSize, positions[index]);
                    });
                } else if (insurerId=== 40) {
                    texts40.forEach((text, index) => {
                        addCenteredText(doc, text, fontSize, positions[index]);
                    });
                } else {
                    texts17.forEach((text, index) => {
                        addCenteredText(doc, text, fontSize, positions[index]);
                    });
                }

                // Save the PDF with a name
                doc.save('Libre_Deuda.pdf');
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
            })
        })
        .catch((error) => {
            setLoading(false)
        })
    })
    .catch((error) => {
        setLoading(false)
    })
}

export const tarjetaCirculacionAction = (policyNumber: any, cobranzaData: any, setLoadingDownload: any) => {
    setLoadingDownload(true)
    let data: any;
    if(cobranzaData?.length === 0) {
        data = { endoso: 0, policyNumber}
    } else {
        data = { endoso: getMaxEndosoItem(cobranzaData)?.endoso, policyNumber}
    }
    axios({
        method: 'post',
        url: tarjetaCirculacionNet(),
        data
    })
        .then((response: any ) => {
            const fileName = 'TarjetaDeCirculacion' + policyNumber + '.pdf';
            fetch(response.data?.url)
                .then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                    setLoadingDownload(false)
                })
                .catch((error) => {
                    console.error('Error downloading the file:', error)
                    setLoadingDownload(false)
                });
        })
        .catch((error) => {
            setLoadingDownload(false)
        })
}

export const cuponPagoAction = (policyNumber: any, cobranzaData: any, setLoadingDownload: any) => {
    setLoadingDownload(true)
    let data: any;
    if(cobranzaData?.length === 0) {
        data = { endoso: 0, policyNumber}
    } else {
        data = { endoso: getMaxEndosoItem(cobranzaData)?.endoso, policyNumber}
    }
    axios({
        method: 'post',
        url: cuponPagoNet(),
        data
    })
        .then((response: any ) => {
            const fileName = 'CuponDePago' + policyNumber + '.pdf';
            fetch(response.data?.cuponUrl)
                .then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    link.download = fileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(url);
                    setLoadingDownload(false)
                })
                .catch((error) => {
                    console.error('Error downloading the file:', error)
                    setLoadingDownload(false)
                });
        })
        .catch((error) => {
            setLoadingDownload(false)
        })
}

const getMaxEndosoItem = (list: any) => {
    return list.reduce((maxItem: any, currentItem: any) => {
      return currentItem.endoso > maxItem.endoso ? currentItem : maxItem;
    }, list[0]);
};

export const coberturaAction = (policyId: any, setLoading: any) => {
    setLoading(true)
    axios.get(netPolizaData(policyId))
    .then((responsePoliza) => {
        axios.get(netUserDataFromPoliza(policyId))
        .then((responseUser) => {
            axios.get(netCheckoutData(responsePoliza?.data?.checkoutId))
            .then((responseCheckout) => {
                axios.get(netGetQuoteQuestions(12, responseCheckout?.data?.offer?.quoteId))
                .then((responseQuote) => {
                    const quoteData = responseQuote?.data
                    const userData = responseUser?.data
                    const policyData = responsePoliza?.data
                    const checkoutData = responseCheckout?.data
    
                    createPdf(userData, checkoutData, policyData, quoteData);

                    setLoading(false)
                })
                .catch((error) => {
                    setLoading(false)
                })
            })
            .catch((error) => {
                setLoading(false)
            })
        })
        .catch((error) => {
            setLoading(false)
        })
    })
    .catch((error) => {
        setLoading(false)
    })
}

export const actionGetCobranzaData = (policyId: any, setCobranzaData: any, setLoading: any) => {
    setLoading(true)
    axios.get(netGetCobranzaData(policyId))
        .then((response) => {
            const res = [...response.data];
            res.sort((a, b) => {
                if (b.endoso !== a.endoso) return b.endoso - a.endoso;
                return new Date(b.vto).getTime() - new Date(a.vto).getTime();
            });  
            setCobranzaData(res)
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
        })
            
}
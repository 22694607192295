import axios from './instanceActions'
import {
    netSaveCotizacion,
    netGetOffer,
    netGetQuoteQuestions,
    netCreateOffer,
    netGetLocalidadText
} from "../net/Connector";
import { createPdf } from '../util/CreateCarfacilCotizacionPdf';

export const postCotizacion = (offerId: any, text: string, setGuardarCotizacionLoading: any, setNotification: any) => {
    setGuardarCotizacionLoading(true)
    axios.get(netGetOffer(12, offerId))
        .then((responseOffer) => {
            axios({
                url: netSaveCotizacion(12, responseOffer?.data?.quoteId),
                method: 'post',
                data: text,
                headers: {
                    'Content-Type': 'text/plain'
                }
            })
                .then((response) => {
                    setGuardarCotizacionLoading(false)
                    setNotification({ success: true, text: 'La cotización se guardó exitosamente.' })
                    setTimeout(() => {
                        setNotification({ success: false, text: '' })
                    }, 5000)
                })
                .catch((error) => {
                    setNotification({ success: false, text: 'No pudimos guardar la cotización. Por favor, intentá nuevamente.' })
                    setTimeout(() => {
                        setNotification({ success: false, text: '' })
                    }, 5000)
                    setGuardarCotizacionLoading(false)
                })
        })
        .catch((error) => {
            setNotification({ success: false, text: 'No pudimos guardar la cotización. Por favor, intentá nuevamente.' })
            setTimeout(() => {
                setNotification({ success: false, text: '' })
            }, 5000)
            setGuardarCotizacionLoading(false)
        })
};

export const actionsGetOffer = (offerId: any, setPackages: any, setLoading: any) => {
    setLoading(true)
    axios.get(netGetOffer(12, offerId))
        .then((responseOffer) => {
            setPackages(responseOffer?.data?.packages)
            setLoading(false)
        })
        .catch((error) => {
            setLoading(false)
        })
}

export const actionDescargarCotizacion = (selectedPackages: any, offerId: any, setLoading: any, insurers: any) => {
    setLoading(true)
    axios.get(netGetOffer(12, offerId))
        .then((responseOffer) => {
            axios.get(netGetQuoteQuestions(12, responseOffer?.data?.quoteId))
                .then((responseQuote) => {
                    const offer = responseOffer.data
                    const quote = responseQuote.data
                    createPdf(offer, quote, selectedPackages, insurers)
                    setLoading(false)
                })
                .catch((error) => {
                    setLoading(false)
                })
        })
        .catch((error) => {
            setLoading(false)
        })
}